:root {
  --grid-cols: 1;
  --grid-rows: 1;
  --main-color: #198115;
  --main-btn-color: #eeeeee;
  --secondary-btn-color: #a3c2a7;
  --primary-font-size: calc(10px + 4vmin);
  --secondary-font-size: calc(6px + 1.8vmin);
}
.App {
  text-align: center;
  background-color: var(--main-color);
  min-height: 100vh;
  min-width: 280px;
  color: white;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--primary-font-size);
  color: white;
  padding: 3rem 0;
  
}

h1 a:visited {
  color: var(--main-btn-color);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-snap-type: y proximity;
}

.content > * {
  
  font-size: var(--secondary-font-size);
}

footer {
  padding-bottom: 1rem;

}

footer small {
  font-size: var(--secondary-font-size);
}

h2 {
  font-size: calc(var(--secondary-font-size) + 10px);
  border-bottom: 2px solid var(--secondary-btn-color);
}


/* Button Component */
.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  scroll-snap-align: start;
}

button {
  height: 35px;
  cursor: pointer;
  opacity: 0.8;
  background-color: var(--main-btn-color);
}

button:hover, button:focus {
  opacity: 1;
}

button:disabled {
  cursor: not-allowed;
  background-color: #9fb995;
}

.buttons p, label {
  display: inline-block;
  height: 25px;
}

.actions-container {
  display: flex;
  flex-direction: column;
  
}

.speed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.speed-container p {
  padding: 0 .7rem;
  height: 35px;
  display: flex;
  align-items: center;
}

.range-container {
  position: relative;
  background-color: var(--main-btn-color);
}

.grid-size {
  position: absolute;
  top: 7px;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
  color: black;

}

.slider {
  z-index: 3;
  -webkit-appearance: none;
  width: 98%;
  height: 25px;
  background: #d3d3d3;
  opacity: .2;
  -webkit-transition: .2s;
  transition: opacity .2s;
  cursor: pointer;
}

.slider:disabled {
  cursor: not-allowed;
  background-color: rgb(252, 145, 145);
}
.slider:hover {
  opacity: 1;
}
.slider:hover + .grid-size {
  z-index: -1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 80px;
  height: 25px;
  background: var(--main-color);
}


.slider::-moz-range-thumb {
  width: 80px;
  height: 25px;
  background: var(--main-color);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .buttons .actions-container {
    flex-direction: row;
  }
  .actions-container .next-gen,.clear {
    flex-grow: 2;
    width: 45%;
  }

  .actions-container .play {
    flex-grow: 1;
    width: 30%;
  }
}


@media screen and (min-width: 1024px){
  .buttons {
    min-width: 70%;
    margin: 0 auto;
  }
}

/* Game Component */

.game-screen {
  background-color: rgb(238, 255, 239);
  scroll-snap-align: start;
}

.grid {
  overflow-x: scroll;
  padding: 2rem 0;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  margin: 0 auto;
}

.cell {
  display: inline-block;
  background-color: var(--secondary-btn-color);
  border: 1px solid black;
  min-width:1.5rem;
  min-height: 1.5rem;
  margin: 0;
}

.alive {
  background-color: var(--main-color);
}

/* Patterns Content */

.samples-container {
  /* padding: 1.2rem .4rem; */
  width: 100%;
}
.samples-container h2 {
  margin-bottom: 1.2rem;
}

.sample-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sample {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-bottom: 2rem;
}

.sample > * {
  padding-bottom: .4em;
}

.sample img {
  height: 320px;
}

.sample select{
  height: 35px;
  margin-bottom: .4rem;
  font-size: x-large;

}

@media screen and (min-width: 1400px) {

  .sample-options {
    flex-direction: row;
    justify-content: space-between;
  }

}
/* Rules Component */

.rules,.samples-container {
  font-size: var(--secondary-font-size);
  padding: 1.2rem .4rem;
  text-align: center;
  margin: 0 auto;
  scroll-snap-align: start;
}

.rules section > * {
  margin-bottom: 1.2em;
  line-height: 1.3;
}

.rules ol {
  list-style-position: outside;
  border-bottom: 2px solid var(--secondary-btn-color);
  padding: 1rem 2vmin;
  border-top: 2px solid var(--secondary-btn-color);
  margin: 0 .4vmin;
  margin-bottom: 1.2em;
}

.rules ol li {
  text-align: left;
  margin: 0 2.5rem;
}


@media screen and (min-width: 1024px){
  .rules,.samples-container {
    max-width: 70%;
  }
}
